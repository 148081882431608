const getDefaultState = () => {
  return {
    planningFilters: [],
    planningParameters: null,
  };
};

const state = getDefaultState();

const getters = {
  getPlanningFilters: (state) => state.planningFilters,
  getPlanningParameters: (state) => state.planningParameters,
};

const mutations = {
  setPlanningFilters (state, payload) {
    let index = state.planningFilters.findIndex((p) => p.path === payload.path);
    if (index > -1) {
      state.planningFilters[index] = payload
    } else {
      state.planningFilters.push(payload)
    }
  },

  resetPlanningFilters (state, payload) {
    state.planningFilters = state.planningFilters.filter((p) => p.path !== payload)
  },

  restorePlanningFilters (state, payload) {
    state.planningFilters = payload
  },
  setPlanningParameters (state, payload) {
    state.planningParameters = payload;
  },

  resetPlanningParameters (state) {
    state.planningParameters = null;
  },
};

export default {
  state,
  getters,
  mutations,
};
