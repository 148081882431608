import getApiLocalPort from "./getApiLocalPort";

export default function setApiRoutes(Vue, system, env) {
  const domain = ".znaptech.com/";

  const setProtocol = (environment) => {
    return environment === "local" ? "http://" : "https://";
  };

  const setApi = (environment) => {
    let apiName = "";

    if (environment === "homol") {
      apiName = "homol.";
    }

    if (environment === "dev") {
      apiName = "dev.";
    }

    return `${system.toLowerCase()}.api.${apiName}`;
  };

  const setRoute = (path) => {
    const protocol = setProtocol(env);

    if (env === "local") {
      const apiLocalPort = getApiLocalPort(path);
      return `${protocol}localhost:${apiLocalPort}/`;
    }

    const api = setApi(env);
    return `${protocol}${api}${path}${domain}`;
  };

  Vue.prototype.$ipUser = setRoute("user");
  Vue.prototype.$ipClient = setRoute("client");
  Vue.prototype.$ipSecurity = setRoute("security");
  Vue.prototype.$ipEvent = setRoute("event");
  Vue.prototype.$ipAccount = setRoute("account");
  Vue.prototype.$ipCustomer = setRoute("customer");
  Vue.prototype.$ipOrganization = setRoute("organization");
  Vue.prototype.$ipUnit = setRoute("unit");
  Vue.prototype.$ipMessage = setRoute("message");
  Vue.prototype.$ipIntegration = setRoute("integration");
  Vue.prototype.$ipSales = setRoute("sales.area");
  Vue.prototype.$ipSalesPlanning = setRoute("sales.planning");
  Vue.prototype.$ipDynamicReport = setRoute("dynamic.report");
  Vue.prototype.$ipPurchase = setRoute("purchase");
  Vue.prototype.$ipPurchasePlanning = setRoute("purchase.planning");
  Vue.prototype.$ipApprovalFlow = setRoute("approval.flow");
  Vue.prototype.$ipProduct = setRoute("product");
  Vue.prototype.$ipComment = setRoute("comment");
  Vue.prototype.$ipVendor = setRoute("vendor");
  Vue.prototype.$ipPlPlanning = setRoute("pl.planning");
  Vue.prototype.$ipPayment = setRoute("payment");
}
