import moment from "moment";
import Vue from "vue";
import store from "@/store";

export const getPermissions = (routePath) => {
  let permissions = {
    crudPermission: true,
    createPermission: true,
    updatePermission: true,
    deletePermission: true,
  };

  const path = routePath
    .toString()
    .substr(1)
    .replaceAll("-", "_")
    .toUpperCase();
  const crudPermission = `CRUD_${path}`;

  const foundCrud = store.getters["auth/getPermissions"].find(
    (p) => p === crudPermission
  );
  if (foundCrud) {
    return permissions;
  } else {
    permissions.crudPermission = false;
  }

  const createPermission = `CREATE_${path}`;
  const foundCreate = store.getters["auth/getPermissions"].find(
    (p) => p === createPermission
  );
  if (!foundCreate) {
    permissions.createPermission = false;
  }

  const updatePermission = `UPDATE_${path}`;
  const foundUpdate = store.getters["auth/getPermissions"].find(
    (p) => p === updatePermission
  );
  if (!foundUpdate) {
    permissions.updatePermission = false;
  }

  const deletePermission = `DELETE_${path}`;
  const foundDelete = store.getters["auth/getPermissions"].find(
    (p) => p === deletePermission
  );
  if (!foundDelete) {
    permissions.deletePermission = false;
  }

  return permissions;
};

export const errorFunction = function(err, tableName) {
  console.log(err);
  store.commit("issue/setLastError", err);

  const defaultErrorMsg = "Ocorreu um problema na comunicação com o servidor";

  if (err.response.data.msg) {
    let msg = err.response.data.msg;

    if (msg) {
      return this.$toast.error(msg);
    } else {
      return this.$toast.error(defaultErrorMsg);
    }
  } else if (err.response.data.message) {
    if (err.response.data.message.includes("Erro exclusão:")) {
      let table = err.response.data.message
        .split(" ")
        .pop()
        .slice(0, -1);

      return this.$toast.error(`Dado pertence a tabela: ${table}`);
    } else if (err.response.data.errno == 1406) {
      let column = err.response.data.message.split("'")[1];

      return this.$toast.error(
        `Quantidade de caracteres acima do permitido para a coluna: ${column}`
      );
    } else if (err.response.data.message.includes("Duplicate entry")) {
      if (tableName) {
        return this.$toast.error(`Dado já existe em: ${tableName}`);
      } else {
        let znapIndex = err.response.data.message.indexOf("znap_");
        let length = err.response.data.message.length;
        let sliced = err.response.data.message.slice(znapIndex, length);
        let dot = sliced.indexOf(".");
        let view = sliced.slice(5, dot);

        return this.$toast.error(`Dado já existe em: ${view}`);
      }
    } else {
      return this.$toast.error(defaultErrorMsg);
    }
  } else {
    return this.$toast.error(defaultErrorMsg);
  }
};

export const calendar = {
  months() {
    return [
      { id: 1, text: "Janeiro" },
      { id: 2, text: "Fevereiro" },
      { id: 3, text: "Março" },
      { id: 4, text: "Abril" },
      { id: 5, text: "Maio" },
      { id: 6, text: "Junho" },
      { id: 7, text: "Julho" },
      { id: 8, text: "Agosto" },
      { id: 9, text: "Setembro" },
      { id: 10, text: "Outubro" },
      { id: 11, text: "Novembro" },
      { id: 12, text: "Dezembro" },
    ];
  },

  quarters() {
    return [
      { id: 1, text: "Tri 01" },
      { id: 2, text: "Tri 02" },
      { id: 3, text: "Tri 03" },
      { id: 4, text: "Tri 04" },
    ];
  },

  semesters() {
    return [
      { id: 1, text: "Sem 01" },
      { id: 2, text: "Sem 02" },
    ];
  },
};

export const filters = {
  dateFilter(value) {
    if (Vue.prototype.$vuetify.framework.lang.current === "pt") {
      moment.locale("pt-br");
    } else {
      moment.locale(Vue.prototype.$vuetify.framework.lang.current);
    }

    return moment.utc(value).format("L");
  },

  floatFilter(value) {
    if (Vue.prototype.$vuetify.framework.lang.current === "pt") {
      return parseFloat(value).toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    } else {
      return parseFloat(value).toFixed(2);
    }
  },

  cpfCnpjFilter(value) {
    let _value = value.replace(/[^\d]/g, "");

    if (_value.length <= 11) {
      return _value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      return _value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
  },
};

export default {
  getPermissions,
  errorFunction,
  calendar,
  filters,
};
